<template>
  <div class="volunteer">
      <div class="volunteer-continar">
     <Redribbon></Redribbon>
     <Workinfo :list="worklist"></Workinfo>
     <Activityvideo :videolist="videolist" :node="type"></Activityvideo>
    </div>
  </div>
</template>

<script>
import Workinfo from './components/workinfo'
import Redribbon from './components/redribbon'
import Activityvideo from './components/activityvideo'
import apiUrls from '@api'
export default {
  components: { 
   Redribbon,
   Workinfo,
   Activityvideo 
   },
  name: 'Volunteer',
  data () {
    return {
      worklist: [],
      videolist: [],
	  type:'zyzfwhszhsd_hdsp'
    }
  },
  created () {
    this.workinfo()
    this.videoinfo()
  },
  methods : {
    workinfo() {
      apiUrls.getvolunteer({node: 'zyzfwhszhsd_gzxx'}).then(res=>{

      this.worklist= res.results.data
      })
    },
    videoinfo () {
      apiUrls.getvolunteer({node: 'zyzfwhszhsd_hdsp'}).then(res=>{

      this.videolist= res.results.data
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
.volunteer {
  background-color: #f5f5f5;
    .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
.nowpostion {
            font-size: 14px;
            color: #333333;
            margin-top: 29px;
            line-height: 27px;
            display: flex;
            &::before {
                content: "";
                display: block;
                height: 27px;
                width: 40px;
                background:url(../../assets/img/position.png) no-repeat center center;
            }
        }
    }
}
</style>
